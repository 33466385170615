<template>
  <base-dialog
    :show="!!error"
    :errorIcon="!!error"
    title="Oops. Something went wrong"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </base-dialog>
  <main-card-header :title="getChecklists.name"></main-card-header>
  <custom-alert class="hidden" title="Warning" :show="show" @close="closeMe">
    <p>Some jobs not completed</p>
    <p>Are you sure you want to continue?</p>
    <br />
    <p><i>Your Progress will be saved*</i></p>
    <template v-slot:actions>
      <div>
        <base-button @click="closeMe">Close</base-button>
        <button>click me</button>
      </div>
    </template>
  </custom-alert>

  <base-dialog :show="isLoading" title="Please wait..." fixed>
    <heart-rate fast="true"></heart-rate>
  </base-dialog>
  <!-- <div v-if="!isLoading"> -->
  <base-button @click="toggleComplete" class="ml-10">Complete All</base-button>
  <form @submit.prevent="submitModal">
    <checklist-item
      v-for="taskList in getTasks"
      :key="taskList.id"
      :taskDetails="taskList"
      :reasons="getReasons"
      :marked="markAll"
      @update-form-data="handleEvent"
      @mark-as-completed="markAsCompleted"
    ></checklist-item>
  </form>
  <div class="text-center">
    <base-button @click="submitModal" class="ml-10"
      >Set Room as Completed</base-button
    >
  </div>
  <!-- </div> -->
  <!-- <custom-alert /> -->
</template>
<script>
import checklistItem from "@/components/layout/CS/checkListItem.vue";
import customAlert from "../../components/ui/baseComponents/customAlert.vue";
export default {
  // beforeRouteLeave(to, from, next) {
  //   if (this.markAll) {
  //     next();
  //   } else {
  //     const userWantsToLeave = confirm("Are you sure you want to leave?");
  //     next(userWantsToLeave);
  //   }
  // },
  components: { checklistItem, customAlert },
  data() {
    return {
      error: null,
      show: true,
      isLoading: false,
      markAll: false,
      completed: "s",
    };
  },
  created() {
    this.loadReasons();
    this.loadChecklist();
  },

  methods: {
    handleError() {
      this.error = null;
    },
    closeMe() {
      this.show = !this.show;
    },
    markAsCompleted(e) {
      this.stopRouting = e;
    },
    handleEvent(e) {
      if (e.status === "0" && e.reason === "") {
        return;
      } else {
        this.updateTask(e);
      }
    },
    async updateTask(payload) {
      try {
        await this.$store.dispatch("cleaner/updateTask", payload);
      } catch (error) {
        console.log(error.message);
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadChecklist() {
      this.isLoading = true;
      let formdata = new FormData();
      formdata.append("room", this.$route.params.roomId);
      try {
        await this.$store.dispatch("cleaner/loadChecklist", formdata);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadReasons() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadReasons");
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    toggleComplete() {
      this.markAll = !this.markAll;
    },
    async submitModal() {
      try {
        let formdata = new FormData();
        formdata.append("status", 1);
        formdata.append("room", this.$route.params.roomId);
        await this.$store.dispatch("cleaner/completeList", formdata);
        this.$router.push({ name: "cs-rooms" });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getReasons() {
      return this.$store.getters["cleaner/getReasons"];
    },
    getChecklists() {
      return this.$store.getters["cleaner/getChecklists"];
    },
    getTasks() {
      return this.$store.getters["cleaner/getTasks"];
    },
  },
};
</script>
