<template>
  <div class="flex justify-center items-center">
    <div
      class="border-2 w-11/12 bg-white rounded-lg shadow-sm hover:shadow-2xl duration-500 px-2  py-4 my-1"
      :class="{
        'border-red': taskDetails.manager_comment,
        'border-lighGrey': !taskDetails.manager_comment,
      }"
    >
      <div
        class="flex flex-col m-auto w-11.5/12 containerLocal sm:grid sm:grid-flow-row h-1/5"
      >
        <!-- Left Side -->
        <div class="left_side sm:grid sm:gap-0 sm:grid-flow-row">
          <div
            class="left_side_top text-xl text-darkGrey font-semibold  sm:flex sm:items-start sm:justify-start"
          >
            <div>
              <div class="text-sm font-normal pb-2">
                <transition name="fade">
                  <div class="" v-if="showComment">
                    {{ taskDetails.manager_comment }}
                  </div>
                </transition>
              </div>
              <div>
                <span
                  class="material-icons mr-1 cursor-pointer"
                  v-if="taskDetails.manager_comment"
                  @click="showComment = !showComment"
                >
                  comment
                </span>
                {{ taskDetails.name }}
              </div>
            </div>
          </div>
          <div
            class="due_text text-grey sm:flex sm:items-end sm:justify-start pb-2 sm-pb-0"
          >
            <p>
              Due:
              <strong>{{
                difference(
                  taskDetails.updated_at,
                  taskDetails.frequency,
                  this.taskStatus
                )
              }}</strong>
            </p>
          </div>
          <!-- Yes Button -->
          <div
            class="yesClass left_side_top_button flex justify-center sm:items-end sm:justify-start sm:pb-0 pb-2"
          >
            <input
              v-model="radioSelected"
              :value="1"
              @change="disableSelect"
              type="radio"
              :checked="taskStatus === 1"
              :name="'radio' + taskDetails.id"
              :id="taskDetails.id + 'yes'"
              :disabled="
                difference(
                  taskDetails.updated_at,
                  taskDetails.frequency,
                  this.taskStatus
                ) !== 'Today' &&
                  (taskDetails.status !== null || taskDetails.status === 1) &&
                  taskDetails.is_processed === 1
              "
              class="border border-green rounded-2xl w-full sm:w-11/12 py-0.5 hover:bg-lightGreen hover:text-white hover:border-lightGreen"
            />
            <label
              :for="taskDetails.id + 'yes'"
              class="yesClass border text-center border-lightGreen rounded-2xl w-full sm:w-11/12 py-0.5 hover:bg-lightGreen hover:text-white hover:border-lightGreen"
            >
              <span>Yes</span>
            </label>
          </div>
          <!-- No Button -->
          <div
            class="noClass left_side_bottom_button flex justify-center sm:items-end sm:justify-start sm:pb-0"
          >
            <input
              v-model="radioSelected"
              :value="0"
              @change="enableSelect"
              :checked="taskStatus === 0"
              type="radio"
              :name="'radio' + taskDetails.id"
              :id="taskDetails.id + 'no'"
              :disabled="
                difference(
                  taskDetails.updated_at,
                  taskDetails.frequency,
                  this.taskStatus
                ) !== 'Today' &&
                  (taskDetails.status !== null || taskDetails.status === 1) &&
                  taskDetails.is_processed === 1
              "
              class="border border-orange rounded-2xl w-full sm:w-11/12 py-0.5 hover:bg-lightGreen hover:text-white hover:border-lightGreen"
            />
            <label
              :for="taskDetails.id + 'no'"
              class="border text-center border-orange rounded-2xl w-full sm:w-11/12 py-0.5 label-checked:bg-lightGreen hover:bg-red hover:text-white hover:border-red"
            >
              <span>No</span>
            </label>
          </div>
          <!-- Reason Dropdown -->
          <div
            class="disabled reason_select flex sm:items-start sm:justify-start justify-center my-0.5 sm:my-0"
          >
            <div class="w-full">
              <label :for="'reasons' + taskDetails.id" class=""
                >Select reason</label
              >
              <select
                v-model="dropDownSelected"
                :disabled="taskStatus === null || taskStatus === 1"
                :name="'reason' + taskDetails.id"
                :id="'reasons' + taskDetails.id"
                class="bg-lightGrey text-grey w-full sm:w-11/12 mt-1 rounded-lg py-0.5"
                @change="customEventToggle($event)"
              >
                <!-- <option value="">Select Reason</option> -->
                <option
                  v-for="reason in reasons.data"
                  :key="reason.id"
                  :value="reason.reason"
                  >{{ reason.reason }}</option
                >
              </select>
            </div>
          </div>
          <div
            class="custom_reason_select  flex sm:items-start sm:justify-start justify-center my-0.5 sm:my-0"
            :class="{
              hidden: taskStatus === null || taskStatus === 1,
            }"
          >
            <div class="w-full">
              <label :for="'customReason' + taskDetails.id" class=""
                >or create custom</label
              >

              <!--Custom Reason Input -->
              <input
                :id="'customReason' + taskDetails.id"
                type="text"
                class="bg-lightGrey w-full sm:w-11/12 rounded-md mt-1 py-0.5"
                v-model="dropDownSelected"
                @blur="leftCustom"
              />
            </div>
          </div>
        </div>
        <div
          class="right_side sm:grid sm:grid-cols-5 sm:gap-0 sm:grid-flow-row"
        >
          <div class="freq_side text-grey sm:flex sm:justify-end">
            <p>
              Frequency:
              <strong>{{ taskDetails.frequency }}</strong>
            </p>
          </div>
          <div class="lastTime_side text-grey">
            <p>
              Last Time:
              <strong>{{ convertedTime(taskDetails.updated_at) }}</strong>
            </p>
          </div>

          <div class="comment_box flex flex-col  sm:justify-end justify-center">
            <div class="   text-grey ">
              <label>Comment:</label>
            </div>
            <textarea
              :name="'comment' + taskDetails.id"
              type="text"
              class="bg-lightGrey rounded-lg w-full h-24 text-sm p-2"
              @blur="updateData"
              v-model="comment"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  emits: ["custom-event-toggle", "update-form-data", "resolve-routing"],
  props: {
    taskDetails: {
      type: Object,
      required: true,
    },
    marked: {
      type: Boolean,
      required: true,
    },
    reasons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showComment: "",
      radioSelected: "",
      dropDownSelected: "",
      comment: "",
      taskStatus: "",
      stopRoutingLocal: "",
    };
  },
  created() {
    this.stopRoutingLocal = this.stopRouting;
    this.comment = this.taskDetails.comment;
    this.radioSelected = this.taskDetails.status;
    this.taskStatus = this.taskDetails.status;
    if (this.taskDetails.reason) {
      this.dropDownSelected = this.taskDetails.reason;
    }
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    difference(data, freq, status) {
      // data = "2021-09-01T00:00:00.000Z"
      // data = "2021-09-15T00:00:00.000Z"
      // data = "2021-10-01T00:00:00.000Z"
      // data = "2021-09-30T00:00:00.000Z"
      // data = "2021-09-29T00:00:00.000Z"
      const today = moment();
      const newData = moment(data);
      const differenceInDays = Math.abs(newData.diff(today, "days"));
      if (freq === "Daily") {
        return "Today";
      } else if (status === null) {
        return "Today";
      } else if (freq === "Weekly") {
        if (differenceInDays >= 7) {
          return "Today";
        } else if (differenceInDays < 7 && differenceInDays >= 0) {
          const final = newData.diff(today, "days") + 7;
          return Math.abs(final) + " Days";
        } else if (differenceInDays === 1) {
          return "Tomorrow";
        }
      } else if (freq === "Monthly") {
        if (differenceInDays >= 30) {
          return "Today";
        } else if (differenceInDays < 30 && differenceInDays >= 0) {
          const final = newData.diff(today, "days") + 30;
          return Math.abs(final) + " Days";
        } else if (differenceInDays === 1) {
          return "Tomorrow";
        } else {
          return "Today";
        }
      }
    },
    updateData() {
      this.$emit("update-form-data", {
        task: this.taskDetails.id,
        status: this.radioSelected,
        reason: this.dropDownSelected,
        comment: this.comment,
      });
    },
    customEventToggle(event) {
      this.updateData("reason", event.target.value);
    },
    disableSelect() {
      this.updateData("status", 1);
      this.taskStatus = 1;
    },
    enableSelect() {
      this.taskStatus = 0;
    },
    leftCustom(event) {
      this.updateData("reason", event.target.value);
    },
    leftComment(event) {
      this.updateData("comment", event.target.value);
    },
  },
  watch: {
    taskStatus() {
      console.log(this.taskStatus);
    },
    marked() {
      if (
        this.difference(
          this.taskDetails.updated_at,
          this.taskDetails.frequency,
          this.taskStatus
        ) === "Today"
      ) {
        this.taskStatus = 1;
        this.radioSelected = 1;
      }
      this.$emit("mark-as-completed", this.radioSelected);
      this.updateData();
    },
  },
};
</script>

<style scoped>
.yesClass input[type="radio"]:disabled + label {
  background-color: #e8edee;
  border-color: #e8edee;
  color: white;
}
.noClass input[type="radio"]:disabled + label {
  background-color: #e8edee;
  border-color: #e8edee;
  color: white;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
input[type="radio"] {
  display: none;
}
.yesClass input[type="radio"]:checked + label {
  background-color: #78be20;
  color: white;
}
.noClass input[type="radio"]:checked + label {
  background-color: #da291c;
  color: white;
}

.containerLocal {
  grid-template-columns: 1.5fr 1fr;
  grid-template-areas: "left_side right_side";
}

.right_side {
  grid-template-rows: 0.6fr 1.4fr;
  grid-template-areas:
    "lastTime_side lastTime_side freq_side freq_side freq_side"
    "comment_box comment_box comment_box comment_box comment_box";
  grid-area: right_side;
}

.freq_side {
  grid-area: freq_side;
}

.lastTime_side {
  grid-area: lastTime_side;
}

.comment {
  grid-area: comment;
}

.comment_box {
  grid-area: comment_box;
}

.left_side {
  grid-template-columns: 0.6fr 1fr 1.4fr;
  grid-template-rows: 0.8fr 0.5fr 0.5fr;
  grid-template-areas:
    "left_side_top left_side_top left_side_top"
    "left_side_top_button due_text ."
    "left_side_bottom_button reason_select custom_reason_select";
  grid-area: left_side;
}

.left_side_top {
  grid-area: left_side_top;
}

.left_side_top_button {
  grid-area: left_side_top_button;
}

.left_side_bottom_button {
  grid-area: left_side_bottom_button;
}

.due_text {
  grid-area: due_text;
}

.reason_select {
  grid-area: reason_select;
}

.custom_reason_select {
  grid-area: custom_reason_select;
}
</style>
