<template>
  <transition name="fade">
    <div
      class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto absolute top-0 left-0"
      v-if="!isVisible"
    >
      <div class="w-full h-full text-center">
        <div class="flex h-full flex-col justify-between">
          <slot></slot>
          <div class="flex items-center justify-between gap-4 w-full mt-8">
            <button
              type="button"
              class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              Delete
            </button>
            <button
              type="button"
              class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-indigo-500 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PopupModal",

  data() {
    return {
      isVisible: false,
    };
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
